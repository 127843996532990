<template lang="pug">
  div
    base-snackbar(
      :errors.sync="costCenterErrors"
    )
    v-data-table(
      :headers="headers"
      :items="costCenterList"
    )
      template(v-slot:item="{ item }")
        tr
          td {{ item.code }}
          td {{ item.description }}
          td
            base-tooltip(
              text
              small
              bottom
              dark
              color="yellow darken-3"
              tooltip="Edit"
              @click="$emit('input', item)"
            )
              v-icon mdi-pencil
</template>
<script>
import costCenterRepository from '@/repositories/cost-center.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [costCenterGetVars, costCenterGetVarNames] = requestVars({ identifier: 'cost-center' })
const costCenterGetHandler = new VueRequestHandler(null, costCenterGetVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Code', value: 'code' },
    { text: 'Description', value: 'description' },
    { text: 'Conf', sortable: false },
  ],
})

export default {
  name: 'CreateCostCenter',
  props: {
    value: {
      type: Object,
      default: () => ({ }),
    },
  },
  created () {
    this.getCostCenters()
    this.initWebsocket()
  },
  data () {
    return {
      ...tableVars(),
      ...costCenterGetVars,
    }
  },
  methods: {
    getCostCenters () {
      const handler = costCenterGetHandler
      const repository = costCenterRepository.index
      handler.setVue(this)
      handler.execute(repository)
    },
    initWebsocket () {
      const { echo } = this.$store.state.websocket
      echo.private('database.event')
        .listen('DBStoreEvent', this.dbStoreEvent)
        .listen('DBUpdateEvent', this.dbUpdateEvent)
    },
    dbStoreEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'CostCenter') {
        this.costCenterList.push(data)
      }
    },
    dbUpdateEvent ({ data, model }) {
      if (this.$objectEmpty(data) || !model) return
      if (model === 'CostCenter') {
        this.costCenterDBUpdateEvent(data)
      }
    },
    costCenterDBUpdateEvent (data) {
      this.costCenterList = this.costCenterList.map(item => {
        if (data.id === item.id) {
          return Object.assign({ ...item, ...data })
        }
        return Object.assign({ ...item })
      })
    },
  },
}
</script>